/* ----------------------------------------------------------------------------------------------------- */
/*  @ Highlight.js overrides
/* ----------------------------------------------------------------------------------------------------- */
code[class*='language-'],
pre[class*='language-'] {
  .hljs-comment,
  .hljs-quote {
    color: #8b9fc1;
    font-style: italic;
  }

  .hljs-doctag,
  .hljs-keyword,
  .hljs-formula {
    color: #22d3ee;
  }

  .hljs-name {
    color: #e879f9;
  }

  .hljs-tag {
    color: #bae6fd;
  }

  .hljs-section,
  .hljs-selector-tag,
  .hljs-deletion,
  .hljs-subst {
    color: #f87f71;
  }

  .hljs-literal {
    color: #36beff;
  }

  .hljs-string,
  .hljs-regexp,
  .hljs-addition,
  .hljs-attribute,
  .hljs-meta-string {
    color: #bef264;
  }

  .hljs-built_in,
  .hljs-class .hljs-title {
    color: #ffd374;
  }

  .hljs-attr,
  .hljs-variable,
  .hljs-template-variable,
  .hljs-type,
  .hljs-selector-class,
  .hljs-selector-attr,
  .hljs-selector-pseudo,
  .hljs-number {
    color: #22d3ee;
  }

  .hljs-symbol,
  .hljs-bullet,
  .hljs-link,
  .hljs-meta,
  .hljs-selector-id,
  .hljs-title {
    color: #e879f9;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: 700;
  }

  .hljs-link {
    text-decoration: underline;
  }
}
