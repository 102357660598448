/* ----------------------------------------------------------------------------------------------------- */
/*  @ Quill editor overrides
/* ----------------------------------------------------------------------------------------------------- */
.ql-toolbar {
  border-radius: 6px 6px 0 0;
  padding: 0 !important;
  font-family: inherit !important;
  @apply bg-gray-100;
  @apply border-gray-300 border-opacity-100 #{'!important'};

  .dark & {
    background-color: rgba(0, 0, 0, 0.05);
    @apply border-gray-500 #{'!important'};
  }

  .ql-formats {
    margin: 11px 8px !important;
  }

  .ql-picker {
    &.ql-expanded {
      .ql-picker-label {
        @apply border-gray-300;

        .dark & {
          @apply border-gray-500;
        }
      }

      .ql-picker-options {
        z-index: 10 !important;
        @apply bg-card border-gray-300;

        .dark & {
          @apply border-gray-500;
        }
      }
    }

    .ql-picker-label {
      @apply text-default;
    }

    .ql-picker-options {
      .ql-picker-item {
        @apply text-default;
      }
    }
  }

  .ql-stroke,
  .ql-stroke-mitter {
    stroke: var(--servicemesh-icon);
  }

  .ql-fill {
    fill: var(--servicemesh-icon);
  }

  button:hover,
  button:focus,
  button.ql-active,
  .ql-picker-label:hover,
  .ql-picker-label.ql-active,
  .ql-picker-item:hover,
  .ql-picker-item.ql-selected {
    @apply text-primary #{'!important'};

    .ql-stroke,
    .ql-stroke-mitter {
      stroke: var(--servicemesh-primary) !important;
    }

    .ql-fill {
      fill: var(--servicemesh-primary) !important;
    }
  }
}

.ql-container {
  overflow: auto;
  min-height: 160px;
  max-height: 400px;
  border-radius: 0 0 6px 6px;
  font-family: inherit !important;
  @apply border-gray-300 border-opacity-100 shadow-sm #{'!important'};

  .dark & {
    @apply border-gray-500 #{'!important'};
  }

  .ql-editor {
    @apply bg-card;

    .dark & {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &.ql-blank::before {
      @apply text-hint;
    }
  }

  .ql-tooltip {
    @apply rounded-md border-gray-300 bg-gray-100 px-3 py-1 shadow-sm;

    .dark & {
      @apply border-gray-700 bg-gray-700 shadow-lg #{'!important'};
    }

    &:before {
      @apply text-secondary;
    }

    .ql-action,
    .ql-remove {
      @apply border-gray-300 text-primary;

      .dark & {
        @apply border-gray-300 text-primary-400;
      }
    }

    .ql-action:after {
      @apply border-r border-r-gray-300 #{'!important'};

      .dark & {
        @apply border-r-gray-500 #{'!important'};
      }
    }

    input {
      @apply text-default rounded-sm border-gray-300 bg-white #{'!important'};

      .dark & {
        @apply border-gray-500 bg-gray-700 #{'!important'};
      }
    }
  }
}
